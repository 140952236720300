import { ReactNode } from 'react'
import { Card, Image } from '@vinted/web-ui'

import { BREAKPOINT_VALUES_MAP } from 'constants/breakpoint'

type DeviceKey = Exclude<keyof typeof BREAKPOINT_VALUES_MAP, 'portables' | 'tabletsUp'>
type ImageKey = DeviceKey | `${DeviceKey}2x`

const imageBreakpoints: Record<ImageKey, number> = {
  phones: BREAKPOINT_VALUES_MAP.phones[1],
  phones2x: BREAKPOINT_VALUES_MAP.phones[1] * 2,
  tablets: BREAKPOINT_VALUES_MAP.tablets[0],
  tablets2x: BREAKPOINT_VALUES_MAP.tablets[0] * 2,
  desktops: BREAKPOINT_VALUES_MAP.desktops[0],
  desktops2x: BREAKPOINT_VALUES_MAP.desktops[0] * 2,
  wide: BREAKPOINT_VALUES_MAP.wide[0],
  wide2x: BREAKPOINT_VALUES_MAP.wide[0] * 2,
}

type Props = {
  phones: string
  phonesContent: ReactNode
  tabletsUpContent: ReactNode
} & Partial<Record<ImageKey, string>>

const HeroBlock = ({ phonesContent, tabletsUpContent, ...images }: Props) => {
  function getSrcset() {
    const srcSet: Array<string> = []

    Object.keys(images).forEach(key => {
      const imageKey = key as ImageKey
      const image = images[imageKey]

      if (!imageKey || !image) return

      srcSet.push(`${image} ${imageBreakpoints[imageKey]}w`)
    })

    return srcSet.join(', ')
  }

  return (
    <div className="hero-block">
      <div className="hero-block__background">
        <Image src={images.phones} scaling={Image.Scaling.Cover} srcset={getSrcset()} alt="" />
        <div className="hero-block__overlay" />
      </div>

      <div className="container u-tablets-up-only">
        <div className="hero-block__wrapper">
          <Card>{tabletsUpContent}</Card>
        </div>
      </div>

      <div className="u-fill-width u-phones-only">{phonesContent}</div>
    </div>
  )
}

export default HeroBlock
