import { ReactNode } from 'react'

import DynamicSpacer from './DynamicSpacer'
import BlockTitle from './BlockTitle'

type Props = {
  children: ReactNode
  afterBlockSpacer?: boolean
  title?: string
}

const BlockArrangement = ({ children, title, afterBlockSpacer = true }: Props) => {
  const spacerBetweenBlocks = <DynamicSpacer phones="XLarge" tabletsUp="X3Large" />

  return (
    <section data-testid="homepage-block">
      {title && (
        <>
          <BlockTitle title={title} />
          <DynamicSpacer phones="Regular" tabletsUp="Large" />
        </>
      )}
      {children}
      {afterBlockSpacer && spacerBetweenBlocks}
    </section>
  )
}

export default BlockArrangement
