import { useContext, useMemo, useSyncExternalStore } from 'react'

import { BrazeContext } from 'libs/common/braze/containers/BrazeProvider'
import { transformBrazePromoBoxCardDto } from 'libs/common/braze/transformers'
import { PromoBoxType } from 'libs/common/braze/constants'

function useBrazePromoBoxes(type: PromoBoxType.BrazeSticky | PromoBoxType.Braze) {
  const { promoBoxCardStore } = useContext(BrazeContext)

  const promoBoxCards = useSyncExternalStore(
    promoBoxCardStore.subscribe,
    () => promoBoxCardStore.state,
    () => null,
  )

  return useMemo(
    () =>
      promoBoxCards
        ?.map(transformBrazePromoBoxCardDto)
        .filter(promoBox => promoBox.type === type) ?? [],
    [promoBoxCards, type],
  )
}

export default useBrazePromoBoxes
