'use client'

import { PropsWithChildren, useMemo } from 'react'

import { HomepageTabModel } from 'types/models/homepage-blocks'

import HomeContext from './HomeContext'

type Props = {
  homepageSessionId: string
  tabs: Array<HomepageTabModel>
}

const HomeProvider = ({ children, homepageSessionId, tabs }: PropsWithChildren<Props>) => {
  const value = useMemo(
    () => ({
      homepageSessionId,
      tabs,
    }),
    [homepageSessionId, tabs],
  )

  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>
}

export default HomeProvider
