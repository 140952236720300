'use client'

import { PropsWithChildren } from 'react'

import ControlPromoBoxTracker from 'libs/common/braze/components/ControlPromoBoxTracker'
import { GenericPromoBoxModel } from 'types/models'

type Props = {
  promoBoxes: Array<GenericPromoBoxModel>
  index: number
}

const ControlPromoBoxRenderItemWrapper = ({
  children,
  promoBoxes,
  index,
}: PropsWithChildren<Props>) => {
  const controlPromoBox = promoBoxes.find(promoBox => promoBox.isControl)

  if (controlPromoBox) {
    return (
      <ControlPromoBoxTracker promoBox={controlPromoBox} index={index}>
        <div className="u-fill-height">{children}</div>
      </ControlPromoBoxTracker>
    )
  }

  return children
}

export default ControlPromoBoxRenderItemWrapper
