'use client'

import { Cell, Text, Spacer } from '@vinted/web-ui'
import { ReactNode, useRef } from 'react'
import { InView } from 'react-intersection-observer'

import useTracking from 'hooks/useTracking'
import { userViewHomepageBlock } from 'libs/common/event-tracker/events'
import useBreakpoint from 'hooks/useBreakpoint'

import BlockTitle from '../../common/BlockTitle'

type Props = {
  name: string
  position: number
  homepageSessionId: string
  id: string
  headerProps?: {
    title: string
    subtitle: string | null
  }
  body: ReactNode
}

const HomepageBlockLayout = ({
  name,
  position,
  id,
  homepageSessionId,
  headerProps,
  body,
}: Props) => {
  const { track } = useTracking()
  const breakpoints = useBreakpoint()

  const isBlockSeen = useRef(false)

  const handleBlockView = (inView: boolean) => {
    if (!inView) return
    if (isBlockSeen.current) return

    isBlockSeen.current = true

    track(
      userViewHomepageBlock({
        blockName: name,
        blockPosition: position,
        homepageSessionId,
        id,
      }),
    )
  }

  const renderHeader = () => {
    if (!headerProps) return null

    const { title, subtitle } = headerProps

    return (
      <>
        <Cell
          styling={Cell.Styling.Tight}
          title={
            <div className="homepage-layouts-text-truncation">
              <BlockTitle title={title} width="Parent" />
            </div>
          }
          body={
            subtitle ? (
              <div className="homepage-layouts-text-truncation">
                <Text type={Text.Type.Subtitle} width={Text.Width.Parent} text={subtitle} as="p" />
              </div>
            ) : null
          }
          testId={`${name}-header`}
          fullWidthTitle
        />
        {breakpoints.phones ? (
          <Spacer size={Spacer.Size.Regular} />
        ) : (
          <Spacer size={Spacer.Size.Large} />
        )}
      </>
    )
  }

  return (
    <InView onChange={handleBlockView}>
      {renderHeader()}
      {body}
    </InView>
  )
}

export default HomepageBlockLayout
