'use client'

import { MouseEvent, useEffect } from 'react'
import { Animation, Button, Dialog, Text } from '@vinted/web-ui'

import useTracking from 'hooks/useTracking'

import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { clickEvent, viewScreenEvent } from 'libs/common/event-tracker/events'
import { PortalMergeBannerModel } from 'types/models'

type Props = {
  banner: PortalMergeBannerModel
  screen: Screen
  show: boolean
  onClose: () => void
}

const PortalMergeModal = ({ banner, screen, show, onClose }: Props) => {
  const { track } = useTracking()

  const {
    animationUrl,
    imageUrl,
    heading,
    text,
    textLinkTitle,
    textLinkUrl,
    primaryActionTitle,
    primaryActionUrl,
    closeActionTitle,
    closeActionUrl,
  } = banner

  useEffect(() => {
    track(
      viewScreenEvent({
        screen,
      }),
    )
  }, [screen, track])

  function trackClick(target: ClickableElement) {
    track(
      clickEvent({
        screen,
        target,
      }),
    )
  }

  function handleActionClick() {
    trackClick(ClickableElement.MergeAnnouncementAction)

    if (primaryActionUrl) return

    onClose()
  }

  function handleCloseClick() {
    trackClick(ClickableElement.CloseScreen)

    if (closeActionUrl) return

    onClose()
  }

  function handleTextClick(event: MouseEvent) {
    if (!(event.target instanceof HTMLAnchorElement)) return

    trackClick(ClickableElement.MergeAnnouncementLink)
  }

  let textLink = ''

  if (textLinkUrl && textLinkTitle) textLink = `<a href="${textLinkUrl}">${textLinkTitle}</a>`

  return (
    <Dialog show={show} hasScrollableContent>
      <div>
        <div className="c-modal__image portal-merge-modal__shrinking-container">
          <Animation
            animationUrl={animationUrl}
            fallbackImageSrc={imageUrl}
            fallbackImageScale={Animation.ImageScaling.Contain}
          />
        </div>

        <div className="c-modal__inline-content">
          <div className="c-modal__title">
            <Text
              as="h1"
              alignment={Text.Alignment.Center}
              text={heading}
              type={Text.Type.Heading}
            />
          </div>
          <div role="none" className="c-modal__body" onClick={handleTextClick}>
            <Text as="span" alignment={Text.Alignment.Center} text={`${text} ${textLink}`} html />
          </div>
        </div>

        <div className="c-modal__actions">
          <div className="c-modal__action">
            <Button
              styling={Button.Styling.Filled}
              url={primaryActionUrl}
              text={primaryActionTitle}
              onClick={handleActionClick}
            />
          </div>
          <div className="c-modal__action">
            <Button
              styling={Button.Styling.Flat}
              url={closeActionUrl}
              text={closeActionTitle}
              onClick={handleCloseClick}
            />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default PortalMergeModal
