'use client'

import { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PersonalizationBanner from 'components/PersonalizationBanner'
import NetPromoterScore from 'components/NetPromoterScore'
import EmailConfirmationBanner from 'components/EmailConfirmationBanner'

import PortalDraftItemReminderBanner from 'components/PortalDraftItemReminderBanner'
import PortalMergeAnnouncementModal from 'components/PortalMergeAnnouncementModal'
import MergeWelcomeScreenModal from 'components/MergeWelcomeScreenModal'
import OnboardingModal from 'components/OnboardingModal'
import CatalogRulesBanner from 'components/CatalogRulesBanner'
import TermsAndConditionsBanner from 'components/TermsAndConditionsBanner'
import { TaxpayerBannerInHome } from 'components/TaxpayerBanner'
import { getBanners } from 'state/banners/selectors'
import { fetchBannersRequest } from 'state/banners/actions'
import useFeatureSwitch from 'hooks/useFeatureSwitch'
import { BannersContext } from 'contexts/BannersProvider'

const TopBanners = () => {
  const bannersRedux = useSelector(getBanners)
  const dispatch = useDispatch()

  const { fetchBanners, banners: bannersContext } = useContext(BannersContext)
  const isFetchBannersUsingContextEnabled = useFeatureSwitch('fetch_home_banners_using_context_api')

  const banners = isFetchBannersUsingContextEnabled ? bannersContext : bannersRedux

  useEffect(() => {
    if (isFetchBannersUsingContextEnabled) {
      fetchBanners()

      return
    }

    dispatch(fetchBannersRequest())
  }, [dispatch, fetchBanners, isFetchBannersUsingContextEnabled])

  function renderBanner<T extends keyof typeof banners>(
    name: T,
    BannerComponent: React.ComponentType<{ banner: NonNullable<(typeof banners)[T]> }>,
  ) {
    const banner = banners[name]

    if (!banner) return null

    return <BannerComponent banner={banner} />
  }

  function renderOnboardingModal() {
    const multiVariantsBanner = banners.multiVariantsOnboardingModal
    const banner = banners.onboardingModal

    if (!banner) return null

    return <OnboardingModal banner={banner} multiVariantsBanner={multiVariantsBanner} />
  }

  return (
    <>
      <div className="homepage-top-banners">
        {renderBanner('taxpayerBanner', TaxpayerBannerInHome)}
        {renderBanner('feedPersonalizationBanner', PersonalizationBanner)}
        {renderBanner('portalMergeDraftItemsReminder', PortalDraftItemReminderBanner)}
        {renderBanner('nps', NetPromoterScore)}
        {renderBanner('emailConfirmation', EmailConfirmationBanner)}
        {renderBanner('termsAndConditions', TermsAndConditionsBanner)}
        {renderBanner('catalogRules', CatalogRulesBanner)}
      </div>
      {/* Modals below */}
      {renderBanner('portalMergeSourceAnnouncement', PortalMergeAnnouncementModal)}
      {renderBanner('portalMergeWelcomeScreen', MergeWelcomeScreenModal)}
      {renderOnboardingModal()}
    </>
  )
}

export default TopBanners
