'use client'

import { Fragment } from 'react'

import { BlockEntityType, ThumbnailsBlockStyle } from 'constants/home'
import {
  HomepageBlockEntityModel,
  HomepageItemBlockModel,
  HomepageBlocksModel,
} from 'types/models/homepage-blocks'
import { BannersBlockModel, ItemsCardsBlockModel, ThumbnailsBlockModel } from 'types/models'
import useStickyPromoBox from 'libs/common/braze/hooks/useStickyPromoBox'
import ExposureBlock from 'components/ExposureBlock'

import ItemsBlock from './ItemsBlock'
import { HorizontalRowsBlock, ThumbnailsBlock, BannersBlock, ItemsCardsBlock } from './Layouts'
import BlockArrangement from '../common/BlockArrangement'
import { useHomeContext } from '../HomeProvider'
import useTabs from '../hooks/useTabs'

type Props = {
  blocks: HomepageBlocksModel
}

const HomeBlocks = ({ blocks }: Props) => {
  const { getStickyPromoBox } = useStickyPromoBox(blocks.promoBox)
  const { homepageSessionId } = useHomeContext()
  const { currentTab } = useTabs()

  if (!blocks.blocks.length) return null

  const renderHomePageGenericBlock = (block: HomepageItemBlockModel, index: number) => {
    if (!blocks) return null

    return (
      <BlockArrangement>
        <ItemsBlock
          {...block}
          promoBox={currentTab.isPromoBoxEnabled ? getStickyPromoBox() : null}
          homepageSessionId={homepageSessionId}
          position={index + 1}
        />
      </BlockArrangement>
    )
  }

  const renderThumbnailsBlock = (block: ThumbnailsBlockModel, index: number) => {
    if (!blocks || !block.elements.length) return null

    if (block.style === ThumbnailsBlockStyle.TwoHorizontalRows) {
      return (
        <BlockArrangement>
          <HorizontalRowsBlock
            {...block}
            position={index + 1}
            homepageSessionId={homepageSessionId}
          />
        </BlockArrangement>
      )
    }

    return (
      <BlockArrangement>
        <ThumbnailsBlock {...block} homepageSessionId={homepageSessionId} position={index + 1} />
      </BlockArrangement>
    )
  }

  const renderBannersBlock = (block: BannersBlockModel, index: number) => {
    if (!blocks || !block.elements.length) return null

    return (
      <BlockArrangement>
        <BannersBlock {...block} position={index + 1} homepageSessionId={homepageSessionId} />
      </BlockArrangement>
    )
  }

  const renderItemsCardsBlock = (block: ItemsCardsBlockModel, index: number) => {
    if (!blocks || !block.cards.length) return null

    return (
      <BlockArrangement>
        <ItemsCardsBlock {...block} position={index + 1} homepageSessionId={homepageSessionId} />
      </BlockArrangement>
    )
  }

  const renderBlock = (block: HomepageBlockEntityModel, index: number) => {
    switch (block.type) {
      case BlockEntityType.ItemBoxBlock:
        return renderHomePageGenericBlock(block.entity, index)
      case BlockEntityType.ThumbnailsBlock:
        return renderThumbnailsBlock(block.entity, index)
      case BlockEntityType.ItemsCardsBlock:
        return renderItemsCardsBlock(block.entity, index)
      case BlockEntityType.BannersBlock:
        return renderBannersBlock(block.entity, index)
      default:
        return null
    }
  }

  let index = -1

  return blocks.blocks.map(block => {
    if (block.type === BlockEntityType.ExposureBlock) {
      return <ExposureBlock {...block.entity} key={block.entity.test_id} />
    }

    index += 1

    return <Fragment key={index}>{renderBlock(block, index)}</Fragment>
  })
}

export default HomeBlocks
