import { useEffect } from 'react'

import { ResponseError } from 'types/api'

import {
  logHomeError,
  incrementPageLoadInitiatedCounter,
  incrementPageLoadFailureCounter,
  logHomeMessage,
} from '../utils/observability'
import useTabs from './useTabs'
import { EMPTY_RESPONSE_ERROR } from '../utils/api'

const useHomeObservability = (homeError: ResponseError | undefined) => {
  const { currentTab } = useTabs()

  useEffect(() => {
    incrementPageLoadInitiatedCounter(currentTab.name)
  }, [currentTab.name])

  const trackEmptyFeed = (feedError: ResponseError) => {
    logHomeMessage(`Feed error: ${feedError.message}`, `tab: ${currentTab.name}`)

    if (homeError) {
      incrementPageLoadFailureCounter({
        tab: currentTab.name,
        feed_reason:
          feedError === EMPTY_RESPONSE_ERROR ? feedError.message : String(feedError.status),
        homepage_reason:
          homeError === EMPTY_RESPONSE_ERROR ? homeError.message : String(homeError.status),
      })
    }
  }

  const trackBoundaryError = (boundaryError: Error | null) => {
    incrementPageLoadFailureCounter({ tab: currentTab.name, homepage_reason: 'boundary' })
    logHomeError(boundaryError)
  }

  return {
    trackEmptyFeed,
    trackBoundaryError,
  }
}

export default useHomeObservability
