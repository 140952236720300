'use client'

import TaxpayerBanner from 'components/TaxpayerBanner/TaxpayerBanner'
import { TaxpayerBannerRenderLocation } from 'constants/taxpayer'
import useFeatureSwitch from 'hooks/useFeatureSwitch'
import { TaxpayerBannerModel } from 'types/models'

type Props = {
  banner: TaxpayerBannerModel
}

const TaxpayerBannerInHome = ({ banner }: Props) => {
  const isTaxpayersSpecialVerificationBanner = banner.isSpecialVerification
  const isTaxpayerSpecialVerificationBannerFsEnabled = useFeatureSwitch(
    'web_special_verification_taxpayers_banners',
  )

  const shouldDisplayBanner =
    !isTaxpayersSpecialVerificationBanner ||
    (isTaxpayersSpecialVerificationBanner && isTaxpayerSpecialVerificationBannerFsEnabled)

  if (!shouldDisplayBanner) return null

  return (
    <TaxpayerBanner
      banner={banner}
      isBannerInFeed
      renderLocation={TaxpayerBannerRenderLocation.Feed}
    />
  )
}

export default TaxpayerBannerInHome
