'use client'

import { Button, Container, Spacer, Text } from '@vinted/web-ui'

import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import useSession from 'hooks/useSession'
import useSystemConfiguration from 'hooks/useSystemConfiguration'
import HeroBlock from 'components/HeroBlock'

import { ClickableElement } from 'constants/tracking/clickable-elements'
import { clickEvent } from 'libs/common/event-tracker/events'
import { HOW_IT_WORKS_URL, ITEM_UPLOAD_URL, SIGNUP_URL } from 'constants/routes'
import { urlWithParams } from 'libs/utils/url'

import { useSellerPromotion } from './hooks/useSellerPromotion'

type Props = {
  visitsCount: number
}

const SellerPromotion = ({ visitsCount }: Props) => {
  const { track } = useTracking()
  const translate = useTranslate()
  const systemConfiguration = useSystemConfiguration()
  const { user } = useSession()
  const { showSellerPromotion } = useSellerPromotion(visitsCount)

  const primaryActionUrl = user?.id
    ? ITEM_UPLOAD_URL
    : urlWithParams(SIGNUP_URL, {
        ref_url: ITEM_UPLOAD_URL,
      })

  function trackTitleClick() {
    const trackData = {
      target: ClickableElement.UploadItem,
      targetDetails: 'Landing page banner Sell now',
    }

    track(clickEvent(trackData))
  }

  function trackSubtitleClick() {
    const trackData = {
      target: ClickableElement.Help,
      targetDetails: 'Landing page banner how-it-works',
    }

    track(clickEvent(trackData))
  }

  function renderTitlePhones() {
    return (
      <Text
        type={Text.Type.Heading}
        text={translate('homepage.seller_promotion.title')}
        alignment={Text.Alignment.Center}
        width={Text.Width.Parent}
        as="h1"
      />
    )
  }

  function renderTitleTabletsUp() {
    return (
      <h1 className="seller-promotion__content-title">
        {translate('homepage.seller_promotion.title')}
      </h1>
    )
  }

  function renderPhonesContent() {
    return (
      <Container>
        <Spacer />
        {renderTitlePhones()}
        <Spacer size={Spacer.Size.Large} />
        <Button
          text={translate('homepage.seller_promotion.actions.start')}
          url={primaryActionUrl}
          styling={Button.Styling.Filled}
          onClick={trackTitleClick}
        />
        <Button
          text={translate('homepage.seller_promotion.actions.learn_more')}
          url={HOW_IT_WORKS_URL}
          styling={Button.Styling.Filled}
          inverse
          onClick={trackSubtitleClick}
        />
      </Container>
    )
  }

  function renderTabletsUpContent() {
    return (
      <div className="u-ui-padding-x2-large">
        {renderTitleTabletsUp()}
        <Spacer size={Spacer.Size.X2Large} />
        <Button
          text={translate('homepage.seller_promotion.actions.start')}
          url={primaryActionUrl}
          styling={Button.Styling.Filled}
          onClick={trackTitleClick}
        />
        <Spacer size={Spacer.Size.Medium} />
        <a href={HOW_IT_WORKS_URL} onClick={trackSubtitleClick}>
          <Text
            as="h3"
            clickable
            text={translate('homepage.seller_promotion.actions.learn_more')}
            type={Text.Type.Subtitle}
          />
        </a>
      </div>
    )
  }

  if (!showSellerPromotion || !systemConfiguration) return null

  return (
    <HeroBlock
      phones={systemConfiguration.welcomeImages.phone}
      tablets={systemConfiguration.welcomeImages.tabletsUp}
      wide={systemConfiguration.welcomeImages.wide}
      phonesContent={renderPhonesContent()}
      tabletsUpContent={renderTabletsUpContent()}
    />
  )
}

export default SellerPromotion
