import { ReactNode } from 'react'

import HorizontalScrollArea from 'components/HorizontalScrollArea'
import { ControlScrollType } from 'components/HorizontalScrollArea/HorizontalScrollArea'

type Props = {
  name: string
  children: ReactNode
  desktopClassName: string
  mobileClassName: string
  desktopView: boolean | undefined
}

const ScrollableContentBody = ({
  name,
  children,
  desktopClassName,
  mobileClassName,
  desktopView,
}: Props) => {
  return desktopView ? (
    <div className={desktopClassName}>{children}</div>
  ) : (
    <HorizontalScrollArea controlsScrollType={ControlScrollType.Partial}>
      <div className={mobileClassName} data-testid={`${name}-mobile-view`}>
        {children}
      </div>
    </HorizontalScrollArea>
  )
}

export default ScrollableContentBody
