'use client'

import { ReactNode, useRef } from 'react'

import { InView } from 'react-intersection-observer'

import HorizontalScrollArea from 'components/HorizontalScrollArea'
import { ProductItem } from 'components/ProductItem'
import PromoBox from 'libs/common/braze/components/PromoBox'
import ErrorBoundary from 'components/ErrorBoundary'
import ControlPromoBoxTracker from 'libs/common/braze/components/ControlPromoBoxTracker'
import { addClickListItemTracking } from 'containers/TrackingProvider'
import { clickEvent, impressionEvent, favouriteItemEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { ContentSource } from 'constants/tracking/content-sources'
import { ListItemContentType } from 'constants/tracking/content-types'
import { ControlScrollType } from 'components/HorizontalScrollArea/HorizontalScrollArea'
import useBrazePromoBoxTracking from 'libs/common/braze/hooks/useBrazePromoBoxTracking'
import { GenericPromoBoxModel, ProductItemModel } from 'types/models'
import { PromoBoxType } from 'libs/common/braze/constants'
import useTracking from 'hooks/useTracking'

import CtaItem from './CtaItem'

const TrackedProductItem = addClickListItemTracking(ProductItem)

type Props = {
  items: Array<ProductItemModel>
  promoBox: GenericPromoBoxModel | null
  homepageSessionId: string
  header?: ReactNode
  cta?: ReactNode
  itemTestId?: string
  handleErrorLogging?: (err: Error | null) => void
  preventLog?: boolean
  testId?: string
  itemsFullWidthAlignment?: boolean
}

const HorizontallyScrollableItems = ({
  items,
  cta,
  promoBox,
  homepageSessionId,
  header,
  itemTestId,
  handleErrorLogging,
  preventLog,
  testId,
  itemsFullWidthAlignment = false,
}: Props) => {
  const { track } = useTracking()
  const { handlePromoBoxClick, handlePromoBoxVisibility } = useBrazePromoBoxTracking(promoBox)

  const seenItemIds = useRef<Array<number>>([])

  const showPromoBox = promoBox && !promoBox.isControl

  if (!items.length) return null

  function handleFavouriteToggle({
    itemId,
    isFollowEvent,
    itemContentSource,
  }: {
    itemId: number
    isFollowEvent: boolean
    itemContentSource?: ContentSource | null
  }) {
    track(clickEvent({ target: ClickableElement.Favourite }))
    track(
      favouriteItemEvent({
        itemId,
        isFollowEvent,
        contentSource: itemContentSource,
        homepageSessionId,
      }),
    )
  }

  const handleItemVisibility = (item: ProductItemModel, position: number) => (inView: boolean) => {
    const { id, user } = item

    if (!inView) return

    if (seenItemIds.current.includes(id)) return

    seenItemIds.current.push(id)

    track(
      impressionEvent({
        id,
        position,
        contentType: ListItemContentType.Item,
        contentSource: item.contentSource as ContentSource,
        itemOwnerId: user.id,
        homepageSessionId,
        searchSignals: item.homepageSignals,
      }),
    )
  }

  function renderPromoBox() {
    if (!showPromoBox) return null

    return (
      <div className="horizontally-scrollable-items__item-content">
        <PromoBox
          image={promoBox.imageUrl}
          color={promoBox.backgroundColor}
          url={promoBox.url}
          impressionUrl={promoBox.impressionUrl}
          onEnter={handlePromoBoxVisibility}
          onClick={handlePromoBoxClick}
          testId={`${String(itemTestId)}-${
            promoBox.type === PromoBoxType.Vinted ? 'legacy' : 'braze'
          }`}
        />
      </div>
    )
  }

  function renderItem(item: ProductItemModel, index: number) {
    const promoBoxOffset = showPromoBox ? 1 : 0
    const position = index + 1 + promoBoxOffset

    return (
      <HorizontalScrollArea.Item
        className="horizontally-scrollable-items__item"
        key={item.id}
        testId={testId}
      >
        <InView
          data-testid={`horizontally-scrollable-item-${item.id}`}
          className="horizontally-scrollable-items__item-content"
          onChange={handleItemVisibility(item, position)}
        >
          <ControlPromoBoxTracker
            promoBox={promoBox}
            index={index}
            className="u-flex-grow u-fill-width"
          >
            <ErrorBoundary
              FallbackComponent={ErrorBoundary.ComponentError}
              preventLog={preventLog}
              onError={handleErrorLogging}
            >
              <TrackedProductItem
                item={item}
                showOwner={false}
                tracking={{
                  id: item.id,
                  contentType: ListItemContentType.Item,
                  contentSource: item.contentSource as ContentSource,
                  position,
                  homepageSessionId,
                  searchSignals: item.homepageSignals,
                }}
                onFavouriteToggle={handleFavouriteToggle}
                showStatus
                testId={itemTestId && `${itemTestId}-${item.id}`}
              />
            </ErrorBoundary>
          </ControlPromoBoxTracker>
        </InView>
      </HorizontalScrollArea.Item>
    )
  }

  return (
    <>
      {header}

      <HorizontalScrollArea
        controlsScrollType={ControlScrollType.Partial}
        itemsFullWidthAlignment={itemsFullWidthAlignment}
      >
        {showPromoBox && (
          <HorizontalScrollArea.Item className="horizontally-scrollable-items__item">
            {renderPromoBox()}
          </HorizontalScrollArea.Item>
        )}

        {items.map(renderItem)}

        {cta}
      </HorizontalScrollArea>
    </>
  )
}

HorizontallyScrollableItems.CtaItem = CtaItem

export default HorizontallyScrollableItems
