import { GenericPromoBoxModel } from 'types/models'

import useBrazePromoBoxes from './useBrazePromoBoxes'
import { PromoBoxType } from '../constants'

const useStickyPromoBox = (legacyPromoBox: GenericPromoBoxModel | null) => {
  const brazeStickyPromoBox = useBrazePromoBoxes(PromoBoxType.BrazeSticky)[0]
  let wasPromoBoxShown = false

  const getStickyPromoBox = () => {
    if (wasPromoBoxShown) return null
    wasPromoBoxShown = true

    if (legacyPromoBox) return legacyPromoBox

    return brazeStickyPromoBox ?? null
  }

  return {
    getStickyPromoBox,
  }
}

export default useStickyPromoBox
