import { useCallback } from 'react'

import useAsset from 'hooks/useAsset'

// TODO: Delete this in favor of useAsset
function useImageAssetsUrl() {
  const asset = useAsset()

  return useCallback(
    (filePath: string) => asset(filePath, { theme: { dark: filePath.replace('.', '-dark.') } }),
    [asset],
  )
}

export default useImageAssetsUrl
