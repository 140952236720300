import { useEffect, useRef } from 'react'

import { transformHomepageBlockResponse } from 'data/api/transformers/response'
import useFetch from 'hooks/useFetch'
import useFeatureSwitch from 'hooks/useFeatureSwitch'
import { ResponseError } from 'types/api'
import { isResponseError } from 'libs/utils/api'

import { getNewParallelHomepageBlocks, getParallelHomepageBlocks } from '../utils/api'
import useTabs from './useTabs'
import { useHomeContext } from '../HomeProvider'
import { observeFetchDuration } from '../utils/observability'

const withDurationTracking =
  <T, A extends Array<unknown>>(
    fn: (...args: A) => Promise<T | ResponseError>,
    isNewApiEnabled: boolean,
  ) =>
  async (...args: A) => {
    const start = performance.now()
    const result = await fn(...args)
    const end = performance.now()
    const state = isResponseError(result) ? 'failure' : 'success'

    observeFetchDuration(end - start, { state, api_type: isNewApiEnabled ? 'new' : 'old' })

    return result
  }

const useFetchHomepageBlocks = () => {
  const { currentTabName, tabs } = useTabs()
  const { homepageSessionId } = useHomeContext()
  const initialTabName = useRef(currentTabName)

  const isNewApiEnabled = useFeatureSwitch('web_homepage_new_api')
  const getBlocks = withDurationTracking(
    isNewApiEnabled ? getNewParallelHomepageBlocks : getParallelHomepageBlocks,
    isNewApiEnabled,
  )

  const {
    fetch: fetchHomepageBlocks,
    transformedData: blocks,
    isLoading,
    error,
  } = useFetch(getBlocks, transformHomepageBlockResponse, { clearDataOnFetch: true })

  useEffect(() => {
    // It is validated before that the first tab exists
    const currentTab = tabs.find(tab => tab.name === initialTabName.current) || tabs[0]!

    fetchHomepageBlocks({
      tab: currentTab,
      homepageSessionId,
    })
  }, [fetchHomepageBlocks, tabs, homepageSessionId])

  return {
    blocks: error ? undefined : blocks,
    refetchBlocks: fetchHomepageBlocks,
    areBlocksLoading: (!blocks && !error) || isLoading,
    error,
  }
}

export default useFetchHomepageBlocks
