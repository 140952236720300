import { range } from 'lodash'

import { getHomepageBatch, getHomepageReference, getHomepageTab, getPromoBox } from 'data/api'
import { isResponseError, isResponseSuccessful } from 'libs/utils/api'
import { ResponseError } from 'types/api'
import {
  BlocksReferenceDto,
  HomepageBlockEntity,
  HomepageBlockEntityDto,
  HomepageBlocksDto,
} from 'types/dtos'
import { ResponseCode } from 'data/api/response-codes'
import { BlockEntityType } from 'constants/home'

import { FetchHomepageBlocksOptions } from '../types'
import { logHomeMessage } from './observability'

export const EMPTY_RESPONSE_ERROR: ResponseError = {
  status: 200,
  code: ResponseCode.Ok,
  message: 'empty',
  errors: [],
  exception: null,
}

export const getParallelHomepageBlocks = async (
  options: FetchHomepageBlocksOptions,
): Promise<HomepageBlocksDto | ResponseError<unknown>> => {
  const { homepageSessionId, tab } = options
  const batchNumbers = range(1, tab.batchCount + 1)

  const promoBoxPromise = tab.isPromoBoxEnabled ? getPromoBox() : null
  const batchPromises = batchNumbers.map(number =>
    getHomepageBatch(number, {
      tabName: tab.name,
      homepageSessionId,
    }),
  )
  const [promoBoxResponse, ...batches] = await Promise.all([promoBoxPromise, ...batchPromises])
  const promoBox =
    promoBoxResponse && isResponseSuccessful(promoBoxResponse) ? promoBoxResponse.promo_box : null
  const blocks = batches
    .filter(isResponseSuccessful)
    .map(result => result.blocks)
    .flat()

  if (blocks.length === 0) {
    const error = batches.find(isResponseError)
    const errorMessage = error ? error.message : EMPTY_RESPONSE_ERROR.message

    logHomeMessage(`Blocks error: ${errorMessage}`, `tab: ${options.tab.name}`)

    return error ?? EMPTY_RESPONSE_ERROR
  }

  return {
    promo_box: promoBox,
    blocks,
  }
}

const isReferenceBlock = (
  block: HomepageBlockEntityDto,
): block is HomepageBlockEntity<BlockEntityType.BlocksReference, BlocksReferenceDto> =>
  block.type === BlockEntityType.BlocksReference

const isNotResponseError = <T extends NonNullable<unknown>>(
  result: T | ResponseError,
): result is T => !('errors' in result)

export const getNewParallelHomepageBlocks = async ({
  homepageSessionId,
  tab,
}: FetchHomepageBlocksOptions): Promise<HomepageBlocksDto | ResponseError> => {
  const promoBoxPromise = tab.isPromoBoxEnabled ? getPromoBox() : null
  const tabResponse = await getHomepageTab({ tabName: tab.name, homepageSessionId })

  if (isResponseError(tabResponse)) {
    logHomeMessage(`Blocks error: ${tabResponse.message}`, `tab: ${tab.name}`)

    return tabResponse
  }

  const blockPromises = tabResponse.blocks.map(async block => {
    if (isReferenceBlock(block)) {
      const referenceResponse = await getHomepageReference(block.entity.name, { homepageSessionId })

      if (isResponseError(referenceResponse)) return referenceResponse

      return referenceResponse.blocks
    }

    return block
  })

  const [promoBoxResponse, ...resolvedBlocks] = await Promise.all([
    promoBoxPromise,
    ...blockPromises,
  ])
  const blocks = resolvedBlocks.filter(isNotResponseError).flat()

  if (blocks.length === 0) {
    const error = resolvedBlocks.find(isResponseError) ?? EMPTY_RESPONSE_ERROR

    logHomeMessage(`Blocks error: ${error.message}`, `tab: ${tab.name}`)

    return error
  }

  return {
    promo_box:
      promoBoxResponse && isResponseSuccessful(promoBoxResponse)
        ? promoBoxResponse.promo_box
        : null,
    blocks,
  }
}
