'use client'

import { useState } from 'react'

import { Screen } from 'constants/tracking/screens'
import { PortalMergeBannerModel } from 'types/models'

import PortalMergeModal from 'components/PortalMergeModal'
import useIncrementalStorageCount from 'hooks/useIncrementalStorageCount'

const STORAGE_KEY = 'hideMergeWelcomeScreenModal'

type Props = { banner: PortalMergeBannerModel }

const MergeWelcomeScreenModal = ({ banner }: Props) => {
  const renderCount = useIncrementalStorageCount(STORAGE_KEY)
  const [show, setShow] = useState(renderCount === 1)

  function handleClose() {
    setShow(false)
  }

  return (
    <PortalMergeModal banner={banner} screen={Screen.Welcome} show={show} onClose={handleClose} />
  )
}

export default MergeWelcomeScreenModal
