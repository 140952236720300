'use client'

import {
  createRef,
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Button, Carousel, Cell, Icon, Image, Loader, Navigation } from '@vinted/web-ui'
import classNames from 'classnames'
import { Speaker24, SpeakerMuted24, X24 } from '@vinted/monochrome-icons'

import useAsset from 'hooks/useAsset'
import useBreakpoint from 'hooks/useBreakpoint'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'

import { OnboardingModalModel, OnboardingSlideModel, OnboardingSlideVideoModel } from 'types/models'
import { clickEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'

type Props = {
  banner: OnboardingModalModel
  onSlideChange: (slideIndex: number) => void
  onClose: () => void
}

const OnboardingVideoVariant = ({ banner, onSlideChange, onClose }: Props) => {
  const breakpoints = useBreakpoint()
  const { track } = useTracking()
  const asset = useAsset('/assets/onboarding-modal')
  const translate = useTranslate()

  const [isMuted, setIsMuted] = useState(true)
  const [slideIndex, setSlideIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [hasAutoplayError, setHasAutoplayError] = useState(false)
  const doesSlideContainsVideo = !!banner.steps[slideIndex]!.video

  const videoRefs = useMemo(
    () =>
      Array(banner.steps.length)
        .fill(0)
        .map(() => createRef<HTMLVideoElement>()),
    [banner.steps.length],
  )

  const getVideoByIndex = useCallback((index: number) => videoRefs[index]?.current, [videoRefs])

  const playVideo = useCallback(async (video: HTMLVideoElement) => {
    try {
      await video.play()
      setHasAutoplayError(false)
    } catch {
      setHasAutoplayError(true)
    }
  }, [])

  useEffect(() => {
    const video = getVideoByIndex(slideIndex)

    if (!video) return undefined

    if (video.readyState === video.HAVE_ENOUGH_DATA) {
      playVideo(video)
    } else {
      video.load()
      setIsLoading(true)
    }

    return () => video.pause()
  }, [getVideoByIndex, playVideo, slideIndex])

  const trackMuteClick = (isCurrentlyMuted: boolean) => {
    track(
      clickEvent({
        screen: banner.name,
        target: isCurrentlyMuted ? ClickableElement.MuteVideo : ClickableElement.UnmuteVideo,
        targetDetails: banner.steps[slideIndex]?.name,
      }),
    )
  }

  const handleSlideInteract = (newSlideIndex: number) => {
    const video = getVideoByIndex(newSlideIndex)

    if (video) video.currentTime = 0
    if (newSlideIndex === slideIndex) return

    setSlideIndex(newSlideIndex)
    onSlideChange(newSlideIndex)
  }

  const toggleMute = () => {
    setIsMuted(current => {
      trackMuteClick(!current)

      return !current
    })
  }

  const handleCanPlay = (event: SyntheticEvent) => {
    const video = getVideoByIndex(slideIndex)

    if (!video) return
    if (event.currentTarget !== video) return

    setIsLoading(false)
    playVideo(video)
  }

  const handleVideoWaiting = (event: SyntheticEvent) => {
    const video = getVideoByIndex(slideIndex)

    if (!video) return
    if (event.currentTarget !== video) return

    setIsLoading(true)
    video.pause()
  }

  const handleVideoClick = (event: MouseEvent<HTMLVideoElement>) => {
    playVideo(event.currentTarget)
  }

  const handleVideoEnd = () => {
    handleSlideInteract(Math.min(slideIndex + 1, banner.steps.length - 1))
  }

  const renderHeading = () => {
    return (
      <div className="u-auto-pointer-events">
        <Navigation
          theme="transparent"
          left={
            doesSlideContainsVideo && (
              <Button
                styling={Button.Styling.Flat}
                inline
                onClick={toggleMute}
                testId="onboarding-video-mute-action"
                iconName={isMuted ? SpeakerMuted24 : Speaker24}
                iconColor={Icon.Color.GreyscaleLevel7}
                aria={{
                  'aria-label': isMuted
                    ? translate('onboarding.a11y.actions.unmute')
                    : translate('onboarding.a11y.actions.mute'),
                }}
              />
            )
          }
          right={
            <Button
              styling={Button.Styling.Flat}
              inline
              onClick={onClose}
              testId="onboarding-close-button"
              iconName={X24}
              iconColor={Icon.Color.GreyscaleLevel7}
              aria={{
                'aria-label': translate('onboarding.a11y.actions.close'),
              }}
            />
          }
        />
      </div>
    )
  }

  const renderFooting = () => {
    if (isLoading) return null

    return (
      <div className="u-auto-pointer-events">
        <Navigation
          theme="transparent"
          body={
            <Cell theme="transparent">
              <Carousel.Navigation
                activeSlide={slideIndex}
                slidesCount={banner.steps.length}
                onBulletSelect={handleSlideInteract}
              />
            </Cell>
          }
        />
      </div>
    )
  }

  const renderVideo = (slideVideo: OnboardingSlideVideoModel, index: number) => {
    const hasPlayButton = hasAutoplayError && index === slideIndex

    return (
      // Disabling eslint, because there's no voiceover on video.
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video
        data-testid={`onboarding-video-${index}`}
        ref={videoRefs[index]}
        muted={isMuted}
        playsInline
        className={hasPlayButton ? 'u-cursor-pointer' : undefined}
        poster={slideVideo.vertical.imageUrl}
        onCanPlayThrough={handleCanPlay}
        onWaiting={handleVideoWaiting}
        onClick={hasPlayButton ? handleVideoClick : undefined}
        onEnded={handleVideoEnd}
      >
        {slideVideo.vertical.formats.map(video => (
          <source src={video.url} key={video.format} type={`video/${video.format}`} />
        ))}
      </video>
    )
  }

  const renderOverlay = () => {
    return (
      <div className="u-position-absolute u-fill-width u-fill-height u-flexbox u-flex-direction-column u-zindex-bump u-no-pointer-events">
        {renderHeading()}
        <div className="u-flex-grow u-justify-content-center u-align-items-center u-flexbox">
          {isLoading && (
            <div data-testid="onboarding-modal-loader">
              <Loader size={Loader.Size.X2Large} />
            </div>
          )}
          {!isLoading && hasAutoplayError && (
            <div data-testid="onboarding-modal-play-button">
              <Image src={asset('/play-button.svg')} size={Image.Size.X2Large} />
            </div>
          )}
        </div>
        {renderFooting()}
      </div>
    )
  }

  const renderSlide = (slide: OnboardingSlideModel, index: number) => {
    const slideVideo = slide.video

    const slideContentClass = classNames('onboarding-modal__slide-content', {
      'onboarding-modal__slide-video-content': slideVideo,
    })

    return (
      <div className={slideContentClass}>
        {slideVideo ? (
          renderVideo(slideVideo, index)
        ) : (
          <Image src={slide.imageUrl} draggable={false} />
        )}
      </div>
    )
  }

  return (
    <div className="u-position-relative">
      {renderOverlay()}
      <Carousel
        index={slideIndex}
        slides={banner.steps.map(renderSlide)}
        arrows={breakpoints.phones ? Carousel.Arrows.Inside : Carousel.Arrows.Outside}
        onSlideInteract={handleSlideInteract}
        hideNavigation
      />
    </div>
  )
}

export default OnboardingVideoVariant
