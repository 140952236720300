'use client'

import { useRef } from 'react'

import { InView } from 'react-intersection-observer'

import useTracking from 'hooks/useTracking'
import { userViewHomepageElement } from 'libs/common/event-tracker/events'
import { ItemsCardModel } from 'types/models'
import { Screen } from 'constants/tracking/screens'
import HorizontalScrollArea from 'components/HorizontalScrollArea'
import { ControlScrollType } from 'components/HorizontalScrollArea/HorizontalScrollArea'

import HomepageBlockLayout from '../HomepageBlockLayout'
import SuggestionsCard from './SuggestionsCard'

type Props = {
  id: string
  name: string
  title: string
  position: number
  homepageSessionId: string
  cards: Array<ItemsCardModel>
}

const ItemsCardsBlock = ({ id, name, title, position, homepageSessionId, cards }: Props) => {
  const seenCards = useRef<Array<string>>([])
  const { track } = useTracking()

  const handleCardView = (card: ItemsCardModel, index: number) => (inView: boolean) => {
    if (!inView) return

    const seenCard = card.id
    if (seenCards.current.includes(seenCard)) return

    seenCards.current.push(seenCard)

    track(
      userViewHomepageElement({
        blockName: name,
        position: index + 1,
        contentSource: card.contentSource,
        contentSourceLabel: '',
        contentSourceId: card.id,
        homepageSessionId,
        screen: Screen.NewsFeed,
      }),
    )
  }

  function renderCards() {
    const cardViews = cards.map((card, index) => (
      <InView
        onChange={handleCardView(card, index)}
        className="items-card-container"
        key={`items-card-${card.id}`}
      >
        <SuggestionsCard
          card={card}
          homepageSessionId={homepageSessionId}
          blockName={name}
          index={index}
        />
      </InView>
    ))

    return cardViews
  }

  return (
    <HomepageBlockLayout
      name={name}
      position={position}
      homepageSessionId={homepageSessionId}
      id={id}
      headerProps={{ title, subtitle: null }}
      body={
        <HorizontalScrollArea controlsScrollType={ControlScrollType.Partial}>
          <div className="cards-layout-container" data-testid={`${name}-mobile-view`}>
            {renderCards()}
          </div>
        </HorizontalScrollArea>
      }
    />
  )
}

export default ItemsCardsBlock
