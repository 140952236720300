'use client'

import { useState } from 'react'

import useExposeBuyerProtectionFeeDiscountTest from 'hooks/useExposeBuyerProtectionFeeDiscountTest'
import ContentLoader from 'components/ContentLoader'
import ErrorBoundary from 'components/ErrorBoundary'
import SellerPromotion from 'components/SellerPromotion'
import { ResponseError } from 'types/api'

import useFetchHomepageBlocks from './hooks/useFetchHomepageBlocks'
import BlockArrangement from './common/BlockArrangement'
import ErrorModal from './common/ErrorModal'
import HomeTabs from './HomeTabs'
import HomeBlocks from './HomeBlocks'
import HomeBanners from './HomeBanners'
import Feed from './Feed'
import useTabs from './hooks/useTabs'
import { useHomeContext } from './HomeProvider'
import useHomeObservability from './hooks/useHomeObservability'

type Props = {
  visitsCount: number
}

const Home = ({ visitsCount }: Props) => {
  const [showErrorModal, setShowErrorModal] = useState(false)

  const { currentTab } = useTabs()
  const { homepageSessionId } = useHomeContext()
  const { blocks, areBlocksLoading, refetchBlocks, error } = useFetchHomepageBlocks()
  const { trackBoundaryError, trackEmptyFeed } = useHomeObservability(error)

  useExposeBuyerProtectionFeeDiscountTest()

  const handleEmptyFeed = (feedError: ResponseError) => {
    trackEmptyFeed(feedError)
    if (error) setShowErrorModal(true)
  }

  const closeModal = () => setShowErrorModal(false)

  return (
    <ErrorBoundary
      FallbackComponent={() => <ErrorModal onCloseClick={closeModal} />}
      preventLog
      onError={trackBoundaryError}
    >
      <HomeTabs onTabClick={tab => refetchBlocks({ tab, homepageSessionId })} />
      {currentTab.isSellerPromotionEnabled && <SellerPromotion visitsCount={visitsCount} />}
      <div className="container">
        <div className="homepage">
          <HomeBanners />
          {!areBlocksLoading && blocks && <HomeBlocks blocks={blocks} />}
          {areBlocksLoading ? (
            <ContentLoader
              size={ContentLoader.Size.Large}
              styling={ContentLoader.Styling.Tight}
              testId="homepage-loader"
            />
          ) : (
            <BlockArrangement afterBlockSpacer={false}>
              <Feed onEmpty={handleEmptyFeed} />
            </BlockArrangement>
          )}
        </div>
      </div>
      {showErrorModal && <ErrorModal onCloseClick={closeModal} />}
    </ErrorBoundary>
  )
}

export default Home
