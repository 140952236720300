'use client'

import { FormattedMessage } from 'react-intl'
import { Button, Loader } from '@vinted/web-ui'
import { useInView } from 'react-intersection-observer'

type Props = {
  isLoading: boolean
  endReached: boolean
  showLoadMoreButton: boolean
  handleLoadMoreButtonClick(): void
  onPageEnd(): void
}

const ItemsLoader = ({
  isLoading,
  endReached,
  showLoadMoreButton,
  handleLoadMoreButtonClick,
  onPageEnd,
}: Props) => {
  const { ref } = useInView({
    rootMargin: '0px 0px 600px 0px',
    onChange: inView => {
      if (!inView) return
      onPageEnd()
    },
  })

  function renderItemsLoader() {
    if (isLoading || endReached) return null
    if (showLoadMoreButton)
      return (
        <div className="u-flexbox u-justify-content-center">
          <Button
            theme="primary"
            styling={Button.Styling.Filled}
            text={<FormattedMessage id="feed.actions.load_more" />}
            onClick={handleLoadMoreButtonClick}
            inline
            testId="feed-load-more-button"
          />
        </div>
      )

    return <div ref={ref} data-testid="feed-observer" />
  }

  return (
    <>
      {renderItemsLoader()}
      {isLoading && (
        <div className="u-flexbox u-justify-content-center">
          <Loader testId="feed-loader" />
        </div>
      )}
    </>
  )
}

export default ItemsLoader
