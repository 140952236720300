'use client'

import useTranslate from 'hooks/useTranslate'
import { cookiesDataByName } from 'libs/common/cookie-manager/cookies-data'
import { getHomepageConfig } from 'data/api/requests'
import { transformHomepageTabDtos } from 'data/transformers/homepage-blocks'
import { HomepageTabModel } from 'types/models/homepage-blocks'
import HomeProvider from 'pages/Home/HomeProvider'
import BannersProvider from 'contexts/BannersProvider'
import useHomeSchemaMarkup from 'hooks/useHomeSchemaMarkup'

import PageHead from '../components/Head/PageHead'
import fullPageLayout from '../layouts/FullPage'
import Home from '../../app/pages/Home'
import { getLayoutServerSideProps } from '../layouts/FullPage/server-props'

type Props = {
  visitsCount: number
  tabs: Array<HomepageTabModel>
  homepageSessionId: string
}

const HomePage = (props: Props) => {
  const translate = useTranslate()
  const pageTitle = translate('homepage.title')
  const schemaMarkup = useHomeSchemaMarkup(pageTitle)

  return (
    <HomeProvider tabs={props.tabs} homepageSessionId={props.homepageSessionId}>
      <BannersProvider>
        <PageHead title={pageTitle} isIndexed titleSuffix="" jsonLd={schemaMarkup} />
        <Home {...props} />
      </BannersProvider>
    </HomeProvider>
  )
}

export default fullPageLayout<Props>(HomePage)

export const getServerSideProps = getLayoutServerSideProps<Props>(async (_, serverProps) => {
  try {
    const response = await getHomepageConfig(serverProps.api)({ throwError: true })

    if (!response.verticals) throw new Error('No verticals field found')
    if (!response.verticals[0]) throw new Error('Verticals array is empty')

    const visitsCount = parseFloat(
      serverProps.api.cookies.get(cookiesDataByName.seller_header_visits) || '0',
    )

    return {
      props: {
        homepageSessionId: response.homepage_session_id,
        tabs: transformHomepageTabDtos(response.verticals),
        visitsCount,
      },
    }
  } catch (error: unknown) {
    const message = error instanceof Error ? error.message : 'Unknown error'

    throw new Error(`Homepage fetch error: ${message}`)
  }
})
