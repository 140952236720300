'use client'

import { Button, Cell } from '@vinted/web-ui'

import useTranslate from 'hooks/useTranslate'

import { ITEM_UPLOAD_URL } from 'constants/routes'

type Props = {
  onClick: () => void
}

const OnboardingVideoActions = ({ onClick }: Props) => {
  const translate = useTranslate('onboarding')

  return (
    <Cell>
      <Button
        styling={Button.Styling.Filled}
        text={translate('actions.sell')}
        url={ITEM_UPLOAD_URL}
        onClick={onClick}
      />
    </Cell>
  )
}

export default OnboardingVideoActions
