import useAbTest from 'hooks/useAbTest'

const useExposeBuyerProtectionFeeDiscountTest = () => {
  useAbTest({
    abTestName: 'bpf_discount_hvf_v2',
    shouldTrackExpose: true,
  })

  useAbTest({
    abTestName: 'bpf_pricing_change_v1_a',
    shouldTrackExpose: true,
  })

  useAbTest({
    abTestName: 'bpf_pricing_change_v1_b',
    shouldTrackExpose: true,
  })

  useAbTest({
    abTestName: 'bpf_tiered_pricing_multiple_markets_v1',
    shouldTrackExpose: true,
  })
}

export default useExposeBuyerProtectionFeeDiscountTest
