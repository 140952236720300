'use client'

import { useEffect, useState } from 'react'

import { PortalMergeBannerModel } from 'types/models'

import { Screen } from 'constants/tracking/screens'
import PortalMergeModal from 'components/PortalMergeModal'
import { getSessionStorageItem, setSessionStorageItem } from 'libs/utils/sessionStorage'

const STORAGE_KEY = 'hidePortalMergeAnnouncementModal'

type Props = {
  banner: PortalMergeBannerModel
}

const PortalMergeAnnouncementModal = ({ banner }: Props) => {
  const [show, setShow] = useState(!getSessionStorageItem(STORAGE_KEY))

  useEffect(() => {
    setSessionStorageItem(STORAGE_KEY, '1')
  }, [show])

  function handleClose() {
    setShow(false)
  }

  return (
    <PortalMergeModal
      banner={banner}
      screen={Screen.MergeAnnouncement}
      show={show}
      onClose={handleClose}
    />
  )
}

export default PortalMergeAnnouncementModal
