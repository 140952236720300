import { Spacer } from '@vinted/web-ui'

type Size = keyof typeof Spacer.Size

type Props = {
  phones: Size
  tabletsUp: Size
}

const DynamicSpacer = ({ phones, tabletsUp }: Props) => {
  return (
    <>
      <div className="u-phones-only">
        <Spacer size={Spacer.Size[phones]} />
      </div>
      <div className="u-tablets-up-only">
        <Spacer size={Spacer.Size[tabletsUp]} />
      </div>
    </>
  )
}

DynamicSpacer.Size = Spacer.Size

export default DynamicSpacer
